.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}
.top-bar-container {
  position: relative;
  @extend .grid-container;
}
.top-bar {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  .logo {
    width: 220px;
  }
}
.title-bar {
  display: flex;
  justify-content: space-between;
  img {
    height: 60px;
  }
}
.top-bar-right {
  a {
    color: $white;
    // font-family: "Din Medium";
    font-family: "Conduit Bold";
    font-size: 1.1rem;
    letter-spacing: 0.5px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  }
  img{height: 1rem;}
}
[role="menuitem"] {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 8px;
    width: 0%;
    height: 2px;
    margin: 0 auto;
    background: $white;
    transition: all 0.5s ease;
  }
  &:hover::after {
    width: 85%;
  }
}
@include breakpoint(medium down) {
  .top-bar {
    padding: 0;
    left: 0;
    ul {
      background: rgba($light-blue, 1);
    }
  }
  .top-bar-left {
    display: none;
  }
  .top-bar-right {
    width: 100%;
    min-height: 100vh;
    a {
      font-size: 1.2rem;
    }
  }
  .submenu {
    width: 100%;
    background-color: rgba($medium-blue, 0.3);
  }
}

.extra-menu-links {
  width: 100%;
  text-align: right;
  padding-bottom: 0.5rem;
  a {
    display: inline-block;
    padding: 0 7px;
    position: relative;
  }
  .with-separator {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 10%;
      right: -3px;
      width: 2px;
      background-color: $white;
      height: 80%;
    }
  }
  @include breakpoint(medium down) {
    display: none;
  }
}
@include breakpoint(large up) {
  .accordion-menu li {
    width: inherit;
  }
  .menu.vertical.nested {
    position: absolute;
  }
}
.dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
  font-family: "Font Awesome 5 Pro";
  border: none;
  content: "\f107";
  right: 20px;
  margin-top: -10px;
}
.is-dropdown-submenu-parent {
  .is-dropdown-submenu {
    opacity: 0;
    transition: opacity 0.5s ease;
    display: block;
    visibility: hidden;
    &.js-dropdown-active {
      visibility: visible;
      opacity: 1;
    }
  }
}
