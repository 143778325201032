header.complaints {
  background-image: linear-gradient(to right, rgba($white, 0.8) 0%, rgba($white, 0.8) 100%),
    url("../img/istockphoto-1018187356-1024x1024.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  h1 {
    color: $purple;
    padding-top: 200px;
    font-family: "Din Medium";
  }
  .complaints-subheader {
    padding: 1rem;
    border-left: 4px solid $orange;
    font-size: 1.3rem;
    color: $light-blue;
    font-family: "Din Medium";
    max-width: 900px;
  }
  p {
    padding-top: 2rem;
    margin-bottom: 0;
    color: $medium-blue;
    font-family: "Din Medium";
  }
}
.numbered-paragraph {
  display: flex;
  flex-direction: row;
  max-width: 900px;
  color: $dark-blue;

  span {
    font-size: 2rem;
    color: $orange;
    padding: 1rem;
    margin-top: 1.5rem;
    font-family: "Din Medium";
    position: relative;
    top: -1rem;
  }
  h3 {
    padding: 0 1rem;
    color: $medium-blue;
    font-family: "Din Medium";
    margin: 2rem 0;
    font-size: 1.1rem;
    border-left: 5px solid $medium-blue;
  }
  p {
    padding-left: 1.3rem;
  }
  address {
    padding-left: 1.3rem;
    font-style: normal;
    a{
        color: $light-blue;
        transition: all .5s ease;
        &:hover{
            color: $dark-blue;
            text-decoration: underline;
        }
    }
  }
  @include breakpoint(small down){
    p,address,span{
      padding-left: 0;
    }
  }
}
.complaint-management-list {
  list-style-type: none;
  i {
    padding-right: 10px;
    padding-top: 5px;
    color: $medium-gray;
    font-size: 1.4rem;
  }
  li {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  @include breakpoint(small down){
    margin-left: 0;
  }
}
.steps-title{
  color: $purple;
  font-family: 'Din Bold';
  padding-bottom: 2rem;
}
