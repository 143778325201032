section.who-we-are-main {
  background-image: url("../img/pexels-photo-845451.jpeg");
  padding: 0;
}
.reliability-section {
  color: $dark-gray;
  background: linear-gradient(135deg ,rgba($white, 1), rgba($light-blue, .3));
  padding: 80px 0;
  h2 {
    font-family: "Din Bold";
    color: $dark-gray;
    font-size: 1.5rem;
  }
  img{
    border: 15px solid $white;
  }
  p{
    font-size: 1.1rem;
  }
  p strong{
    font-family: 'Din Medium';
  }
  @include breakpoint(medium up){
    .medium-7{
      padding-right: 2rem;
    }
  }
}
.service-section {
  padding: 80px 0;
  background: rgba(16, 39, 96, 0.85);
  color: $white;
  p{
    font-size: 1.1rem;
  }
  h2 {
    font-family: "Din Bold";
  }
  .grid-x {
    align-items: center;
  }
  .cell{
    padding-left:  2rem;
    padding-right:  2rem;
  }
  .slogan {
    font-family: "Din Bold";
    font-size: 1.4rem;
    text-align: right;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      right: -2px;
      height: 100%;
      width: 7px;
      background-color: $light-blue;
      top: 0;
      @include breakpoint(small down){
        right: 0px;
      }
    }
  }
}
section.progressive-direct {
  padding: 0;
  box-shadow: 0px 10px 10px -10px #e6e6e6;
  p{
    font-size: 1.1rem;
  }
  h2 {
    color: $white;
    font-family: "Din Bold";
    text-transform: uppercase;
    text-align: right;
    @include breakpoint(medium down){
      font-size: 1.5rem;
    }
  }
  .cell {
    padding: 80px 2rem;
  }
  .medium-4 {
   background-attachment: fixed;
   background-repeat: no-repeat;
   background-size: cover;
  background-position: top right;
  }
}
section.goals{
  font-size: 1.1rem;
}
section.social-responsibιlity {
  // padding: 0;
  background-image: url("../img/social-responsibility.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: $purple;
  p{
      padding-bottom: 2rem;
      font-family: 'Din Medium';
      font-size: 1.1rem;
  }
  a{
      font-family: 'Din Medium';
  }
}
