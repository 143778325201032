header.home {
  height: 100vh;
  background-image: linear-gradient(to bottom, rgba($black, .2) 0%, rgba($black, 0) 100%) , url("../img/home-header.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  overflow: hidden;
  z-index: 0;
  h1 {
    font-family: "Conduit Bold";
    color: $medium-blue;
    font-size: 5.5rem;
    line-height: 1;
    @include breakpoint(small down) {
      font-size: 3rem;
    }
  }
  .header-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // @include breakpoint(small down) {
    //   padding-top: 150px;
    // }
  }
  .home-subheader {
    font-family: "Din Light";
    font-size: 2rem;
    color: $medium-blue;
    a {
      color: $medium-blue;
      transition: all 0.5s ease;
      &:hover {
        color: $purple;
      }
    }
  }
  &::after {
    position: absolute;
    z-index: -1;
    top: 0;
    right: -90vh;
    content: "";
    width: 180vh;
    height: 180vh;
    background: rgba($medium-blue, 0.6);
    border-radius: 100vh;
    border: 50vh solid rgba($white, 0.4);
    background-clip: padding-box;
    mix-blend-mode: overlay;
  }
}

.roadside-help {
  @extend .purple-background;
  padding: 40px 0;
  h2 {
    font-family: "Din Light";
    display: inline-block;
    text-align: left;
  }
  a {
    color: $white;
    text-align: right;
    font-size: 2.4rem;
    transition: all 0.5s ease;
    font-family: "Din Bold";
    &:hover {
      color: $light-blue;
    }
  }
  .justify {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include breakpoint(small down) {
      justify-content: center;
    }
  }
}
section.light-blue-circle {
  position: relative;
  overflow: hidden;
  z-index: 0;
  text-align: center;
  &::after {
    position: absolute;
    z-index: -1;
    top: -117vh;
    right: -90vh;
    content: "";
    width: 180vh;
    height: 180vh;
    background: rgba($light-blue, 0.2);
    border-radius: 100vh;
    border: 50vh solid rgba($light-blue, 0.1);
    background-clip: padding-box;
  }
  p {
    color: $light-blue;
    font-size: 1.3rem;
  }
  a {
    font-family: "Din Bold";
  }
}
section.insurance-category {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
  height: 500px;
  overflow: hidden;
  margin-bottom: 20px;
  .blue-circle {
    background-color: rgba($medium-blue, 0.6);
    text-align: center;
    height: 400px;
    width: 400px;
    margin-left: auto;
    border-radius: 200px;
    position: relative;
    overflow: hidden;
    top: 300px;
    &:hover {
      .insurance-category-links {
        top: 17px;
        @include breakpoint(small down) {
          top: 45px;
        }
      }
    }
  }
  @include breakpoint(medium down) {
    height: 380px;
    .blue-circle {
      top: 180px;
    }
  }


  h2 {
    font-family: "Conduit Bold";
    color: $white;
    text-shadow: 1px 1px 2px $black;
    padding-top: 1.1rem;
    line-height: 1.1;
    position: relative;
    top: 15px;
    @include breakpoint(small down){
      font-size: 2rem;
    }
  }
  .insurance-category-links {
    background-color: $purple;
    position: relative;
    top: 63px;
    width: 100%;
    color: white;
    transition: all 0.5s ease;
    padding: 10px;
    @include breakpoint(small down) {
      top: 95px;
    }
    a {
      color: $light-blue;
      font-family: "Din Medium";
      font-size: 1.2rem;
      transition: all 0.5s ease;
      &:hover {
        color: $white;
      }
    }
  }
}
.insurance-category-box {
  background-color: rgba($light-blue, 0.1);
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  transition: all 0.5s ease;
  &:hover {
    background-color: rgba($light-blue, 0.4);
    a {
      color: $purple;
      i {
        padding-left: 10px;
      }
    }
  }
  h2 {
    color: $medium-blue;
    font-family: "Din Light";
    span {
      font-family: "Din Black";
    }
  }
  a {
    font-size: 1.2rem;
  }
}
section.mobile-insurance {
  background: $medium-blue; /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, $medium-blue, $purple); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    $medium-blue,
    $purple
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: $white;
  font-size: 1.1rem;
  a {
    color: $white;
    transition: all 0.5s ease;
    padding: 0.5rem;
    margin-left: -0.5rem;
    &:hover {
      background-color: $white;
      color: $purple;
    }
  }
  img {
    max-width: 350px;
    height: auto;
    @include breakpoint(small down) {
      margin-top: 2rem;
    }
  }
  .grid-x {
    align-items: center;
  }
}
