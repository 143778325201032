section {
  padding: 80px 0;
  &.no-padding {
    padding: 0;
  }
}

.purple-background {
  background-color: $purple;
  color: $white;
}
.medium-blue-background {
  background-color: $medium-blue;
  color: $white;
}
.medium-blue-gradient-bg {
  background: rgb(255, 255, 255);
  background: linear-gradient(153deg, rgba(255, 255, 255, 1) 0%, rgba(173, 197, 226, 1) 100%);
}
.orange-color {
  color: $orange;
}
.light-blue-color {
  color: $light-blue;
}
.dark-blue-color {
  color: $dark-blue;
}
.purple-color {
  color: $purple;
}
h1,
h2,
h3,
h4 {
  span {
    font-family: "Din Bold";
  }
}
.bold-text {
  font-family: "Din Bold";
}
section.jcc {
  color: $white;
  background-image: linear-gradient(to right, rgba($medium-blue, 0.8), rgba($medium-blue, 0.8)), url("../img/jcc.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  img {
    width: 300px;
    padding: 2rem 0;
  }
  p {
    max-width: 500px;
    margin: 0 auto;
    font-size: 1.2rem;
    padding-bottom: 2rem;
  }
  a {
    font-family: "Din Bold";
  }
}

footer {
  background-color: $dark-blue;
  color: $light-blue;
  h2 {
    color: white;
    font-size: 1.1rem;
    font-family: "Din Medium";
    margin-bottom: 1rem;
  }
  i {
    padding-right: 10px;
  }
  a {
    color: $light-blue;
    transition: all 0.5s ease;
    &:hover {
      color: $white;
    }
  }
  .grid-x {
    justify-content: space-evenly;
  }
  .address-container {
    height: 3rem;
    position: relative;
    i {
      padding-right: 20px;
    }
    @include breakpoint(medium only) {
      height: 5rem;
    }
  }
  address {
    display: inline;
    position: absolute;
    font-style: normal;
  }
  .footer-logo-section-social {
    margin-top: 2rem;
    a {
      color: $light-blue;
      font-size: 2rem;
      padding: 0 0.5rem;
      transition: all 0.5s ease;
      &:hover {
        color: $white;
      }
    }
  }
  .footer-info-section {
    border-top: 2px solid rgba($color: $light-blue, $alpha: 0.2);
    border-bottom: 2px solid rgba($color: $light-blue, $alpha: 0.2);
    margin: 0 auto;
    padding: 2rem 0;
    max-width: 1000px;
  }
  .footer-info-line {
    padding-bottom: 1rem;
  }
  .copyright {
    color: rgba($white, 0.4rem);
    text-align: center;
    padding: 2rem 0;
    img {
      height: 1rem;
    }
  }
  .footer-logo {
    padding: 0 10px;
  }
}

header.base-page {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 70vh;
  background-color: $light-blue;
  position: relative;
  background-image: url("../img/who-we-are.jpeg");
  .grid-container {
    display: flex;
    height: 100%;
    @include breakpoint(small down) {
      padding: 0;
    }
  }
  &--short {
    height: 40vh;
  }

  .header-container {
    background-color: rgba($medium-blue, 0.7);
    border-radius: 100px;
    text-align: center;
    color: $white;
    text-shadow: 1px 1px 2px $black;
    height: auto;
    min-height: 111px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: auto;
    width: 100%;
    z-index: 0;
    h1 {
      font-size: 2.8rem;
      max-width: 740px;
      line-height: 1;
      margin-bottom: 0;
    }
    img.base-page-icon {
      position: absolute;
      height: 100px;
      bottom: 0;
      left: 80px;
      padding: 1rem;
      max-width: 110px;
      @include breakpoint(medium down) {
        padding: 0;
        height: 60px;
        width: 60px;
        left: 5px;
        bottom: 15px;
      }
      @include breakpoint(small down) {
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
    &::before {
      position: absolute;
      content: "";
      bottom: -10px;
      width: 1400px;
      height: 131px;
      left: -1180px;
      background-color: $medium-blue;
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
      z-index: -1;
    }
    &::after {
      position: absolute;
      content: "";
      bottom: -10px;
      width: 1400px;
      height: 131px;
      background-color: $white;
      left: -1200px;
      border-radius: 100px;
      z-index: -1;
    }
    @include breakpoint(medium down) {
      &::before {
        left: -1310px;
      }
      &::after {
        left: -1320px;
      }
      padding: 0 3rem;
    }
    @include breakpoint(small down) {
      height: auto;
      border-radius: 0;
      h1 {
        font-size: 2.5;
        line-height: 1;
        padding: 10px 0;
      }
      &::before {
        top: -108px;
        width: 131px;
        height: 131px;
        border-radius: 100px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
      &::after {
        top: -118px;
        width: 131px;
        height: 131px;
        border-radius: 100px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
      img.base-page-icon {
        top: -85px;
      }
    }
  }
}

ul.shield-list {
  list-style-type: none;
  margin-left: 1.85rem;
  li {
    position: relative;
    padding-bottom: 10px;

    &::before {
      position: absolute;
      content: "\f3ed";
      font-family: "Font Awesome 5 Pro";
      left: calc(-1rem - 10px);
      color: $light-blue;
    }
  }
}
a.insurance-category-link {
  position: relative;
  z-index: 0;
  overflow: hidden;
  margin: 1rem 0;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: rgba($color: $light-blue, $alpha: 0.2);
    top: 0;
    left: -100%;
    transition: all 0.5s ease;
  }
  &:hover::before {
    left: 0;
  }
  img {
    height: 90px;
  }
  h2 {
    font-size: 1.4rem;
    color: $purple;
    padding-top: 1rem;
    line-height: 1;
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
  }
}
.breadcrumbs {
  font-family: "Din Bold";
  color: rgba($medium-gray, 0.8);
  text-transform: uppercase;
  font-size: 0.9rem;
  i {
    padding-right: 5px;
  }
}
.insurance-page-documents-container {
  position: relative;
  padding-left: 1rem;
  &::before {
    position: absolute;
    content: "";
    width: 2px;
    background: $medium-blue;
    height: 100%;
    top: 0;
    left: -15px;
  }
  h3 {
    font-size: 1.2rem;
    font-family: "Din Bold";
    color: rgba($color: $light-blue, $alpha: 0.5);
    padding: 1rem 0;
    margin: 0;
    &:nth-of-type(2) {
      padding-top: 2rem;
    }
  }

  a.button {
    margin-top: 1.5rem;
    max-width: 100%;
  }
}
a.pdf-link {
  display: block;
  color: $purple;
  position: relative;
  font-family: "Din Light";
  position: relative;
  padding-bottom: 10px;
  &::before {
    content: "\f1c1";
    color: $orange;
    font-family: "Font Awesome 5 Pro";
    padding-right: 5px;
  }
  &.regular {
    font-family: "Din Regular";
  }
}
h2.career-header {
  font-size: 1.3rem;
  color: $light-blue;
  font-family: "Din Bold";
}
.subheading {
  font-size: 1.3rem;
  color: $light-blue;
  font-family: "Din Bold";
}
.statement {
  font-size: 1.4rem;
  color: $light-blue;
  padding: 2rem 0;
  a {
    color: $medium-blue;
  }
}
.insurance-page-main {
  padding-right: 2rem;
  @include breakpoint(small down) {
    padding-right: inherit;
  }
  h2 {
    color: $light-blue;
    font-family: "Din Bold";
    font-size: 1.4rem;
    padding-bottom: 1rem;
  }
  h3 {
    color: $dark-blue;
    font-family: "Din Bold";
    font-size: 1.2rem;
    padding-top: 2rem;
  }
  p {
    color: $dark-blue;
  }
  ul {
    @extend ul.shield-list;
  }

  .career-description {
    color: $dark-blue;
    h2 {
      color: $purple;
      font-size: 1.6rem;
      text-transform: uppercase;
      font-family: "Din Medium";
      padding-bottom: 0;
    }
    h3 {
      text-transform: uppercase;
      font-size: 1.2rem;
      font-family: "Din Regular";
      color: $medium-blue;
      padding-top: 0;
    }
  }
}
.insurance-class {
  background-color: rgba($color: $light-blue, $alpha: 0.2);
  padding: 1.5rem 2rem;
  margin: 1rem 0;
  h3 {
    padding: 0;
    position: relative;
    span {
      font-family: "Din Regular";
    }
    &::before {
      position: absolute;
      content: "";
      width: 50px;
      left: -60px;
      top: 0px;
      height: 1.4rem;
      @include breakpoint(small down) {
        width: 37px;
        left: -42px;
      }
    }
    &.crystal {
      color: $light-blue;
      &::before {
        background-color: $light-blue;
      }
    }
    &.silver {
      color: $medium-gray;
      &::before {
        background-color: $medium-gray;
      }
    }
    &.gold {
      color: #d4af38;
      &::before {
        background-color: #d4af38;
      }
    }
    &.diamond {
      color: $medium-blue;
      &::before {
        background-color: $medium-blue;
      }
    }
    &.onyx {
      color: #0f0f0f;
      &::before {
        background-color: #0f0f0f;
      }
    }
    &.bronze {
      color: #cd7f32;
      &::before {
        background-color: #cd7f32;
      }
    }
  }
}
.overflow-image-section {
  @extend .medium-blue-gradient-bg;

  .cell:first-of-type {
    position: relative;
    padding-right: 1rem;
    img {
      position: absolute;
      max-width: none;
      right: 1rem;
      max-height: 100%;
    }
  }
  h2 {
    color: $light-blue;
    font-family: "Din Bold";
    font-size: 1.2rem;
  }
  .cell:nth-of-type(2) {
    padding-left: 1rem;
  }
}
.section-split-by-line {
  padding-top: 2rem;
  h2 {
    color: $dark-blue;
    font-family: "Din Bold";
    font-size: 1.4rem;
  }
  h3 {
    font-family: "Din Bold";
    color: $light-blue;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  .header-image {
    width: 70px;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  .container-with-line {
    padding-right: 1rem;
    text-align: right;
    position: relative;
    padding-bottom: 1rem;
    &::after {
      content: "";
      position: absolute;
      width: 8px;
      height: 100%;
      background: $light-blue;
      top: 0;
      right: -8px;
    }
    @include breakpoint(small down) {
      &::after {
        right: -10px;
      }
    }
  }
  .medium-9 {
    padding-left: 2rem;
    @include breakpoint(small down) {
      padding-left: 1rem;
    }
  }
  .paragraph-with-icon {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 1rem;
    img {
      width: 30px;
    }
    i {
      font-size: 1.8rem;
      color: rgba($light-blue, 0.6);
      width: 31px;
    }
    p {
      padding-left: 2rem;
      @include breakpoint(small down) {
        padding-left: 1rem;
      }
    }
  }
}
.phone-title {
  font-size: 1.4rem;
  color: $purple;
}
.large-phone-link {
  font-size: 2rem;
  font-family: "Din Black";
  color: $purple;
  position: relative;
  top: -12px;
}
.document-list {
  list-style-type: none;
  border-left: 4px solid rgba($color: $light-blue, $alpha: 0.4);
  margin-left: 3px;
  padding-left: 1rem;
  li a {
    font-family: "Din Regular";
    color: $dark-blue;
    transition: all 0.5s ease;
    &:hover {
      color: $light-blue;
    }
  }
}
.alphabet-links {
  a {
    font-size: 3rem;
    color: $medium-blue;
    padding: 0 5px;
    opacity: 0.7;
    font-family: "Din Bold";
    &[aria-disabled="true"] {
      opacity: 0.1;
      cursor: not-allowed;
    }
  }
  &-bordered {
    position: relative;
    padding-left: 2rem;
    max-width: 280px;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 8px;
      background: $light-blue;
      height: 100%;
      @include breakpoint(small down) {
        left: -10px;
      }
    }
    a.is-active {
      text-decoration: underline;
      opacity: 1;
    }
  }
}
.dictionary-container {
  h2 {
    text-align: right;
    color: rgba($color: $medium-blue, $alpha: 0.8);
    font-size: 6rem;
    font-family: "Din Bold";
    position: relative;
    top: 30px;
  }
  h3 {
    color: $light-blue;
    font-family: "Din Medium";
    font-size: 1.4rem;
  }
  padding-right: 2rem;
  @include breakpoint(small down) {
    order: 2;
  }
}
a {
  .fa-chevron-right {
    transition: all 0.5s ease;
  }
  &:hover {
    .fa-chevron-right {
      padding-left: 10px;
    }
  }
}

section.news-articles {
  background-color: rgba($light-blue, 0.06);
  h2 {
    color: $medium-blue;
  }
  article {
    position: relative;
    padding-bottom: 1rem;
    z-index: 0;
    h3 {
      font-size: 1.2rem;
      font-family: "Din Bold";
      padding: 1rem 10px;
      color: $medium-blue;
    }
    a.top-level-link {
      padding: 0;
    }
    a {
      padding: 0 10px;
      font-family: "Din Bold";
      color: $purple;
    }
    span {
      position: absolute;
      top: 10px;
      right: -5px;
      background-color: $light-blue;
      color: $white;
      padding: 10px 10px;
      z-index: 1;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba($light-blue, 0);
      transition: all 0.5s ease;
      z-index: -1;
    }
    &:hover::before {
      background-color: rgba($light-blue, 0.1);
    }
    &:hover {
      img {
        filter: saturate(150%);
      }
      a {
        i {
          padding-left: 10px;
        }
      }
    }
  }
}
.rich-text {
  @include breakpoint(small down) {
    .responsive-object {
      position: relative;
    }
    .responsive-object iframe,
    .responsive-object object {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
