.contact-form-header {
  text-align: right;
  padding-right: 2rem;
  position: relative;
  img {
    height: 100px;
    padding-bottom: 1rem;
  }
  h2 {
    font-family: "Din Bold";
    font-size: 1.4rem;
    color: $medium-blue;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    background: $light-blue;
    height: 100%;
    @include breakpoint(small down) {
      right: -10px;
    }
  }
}
form.contact {
  label {
    font-family: "Din Bold";
  }

  input[type="submit"] {
    width: 100%;
    background-color: $medium-blue;
    color: $white;
    font-family: "Din Medium";
    transition: all 0.5s ease;
    &:hover {
      color: $dark-blue;
      background-color: $light-blue;
    }
  }
}
.map-container {
  position: relative;
  ul {
    // margin-bottom: 0;
    list-style-type: none;
    margin: 0;
    z-index: 0;
  }
  li {
    display: inline-block;
    padding: 1rem 2rem;
    border-radius: 0 100px 100px 0;
    transition: all 0.5s ease;
    width: 200px;
    text-align: center;

    &:nth-of-type(1) {
      z-index: 2;
      position: relative;
      background-color: $white;
      @include breakpoint(small down) {
        width: 140px;
      }
    }
    &:nth-of-type(2) {
      left: -205px;
      position: relative;
      width: 350px;
      text-align: right;
      &.is-active {
        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 82%, rgba(37, 102, 178, 1) 82%);
      }
      @include breakpoint(small down) {
        left: -30px;
        width: 155px;
        .address-info.limassol {
          left: -125px;
        }
      }
    }

    a.city-name {
      color: $medium-blue;
      text-transform: uppercase;
      font-family: "Din Bold";
      font-size: 1.2rem;
      bottom: -10px;
    }
    &:hover {
      background-color: lighten($light-blue, 30%);
    }
    .address-info {
      display: none;
    }
    &.active {
      background-color: $medium-blue;
      a {
        color: $white;
      }
      .address-info {
        display: inherit;
        position: absolute;
        z-index: 1;
        top: 68px;
        left: 0;
        width: 520px;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.95) 45%, rgba(255, 255, 255, 0) 100%);
        height: 500px;
        padding: 1rem 2rem;
        color: $dark-blue;
        text-align: left;

        @include breakpoint(medium down) {
          width: 330px;
        }
        @include breakpoint(small down) {
          width: 100vw;
          height: 300px;
          padding: 1rem;
        }
        i {
          color: $light-blue;
          padding-right: 10px;
        }
        a {
          color: $dark-blue;
          transition: all 0.5s ease;
          &:hover {
            color: $light-blue;
          }
        }
        h3 {
          font-family: "Din Bold";
          font-size: 1.2rem;
          color: $light-blue;
        }
        .address-container {
          height: 3rem;
          position: relative;
          i {
            padding-right: 20px;
          }
          @include breakpoint(medium only) {
            height: 5rem;
          }
        }
        address {
          display: inline;
          position: absolute;
          font-style: normal;
        }
        .footer-logo-section-social {
          margin-top: 2rem;
          a {
            color: $light-blue;
            font-size: 2rem;
            padding: 0 0.5rem;
          }
        }
        .footer-info-section {
          border-top: 2px solid rgba($color: $light-blue, $alpha: 0.2);
          border-bottom: 2px solid rgba($color: $light-blue, $alpha: 0.2);
          margin: 0 auto;
          padding: 2rem 0;
          max-width: 1000px;
        }
        .footer-info-line {
          padding-bottom: 1rem;
        }
        .xs-fixed-height {
          @include breakpoint(small down) {
            height: 80px;
          }
        }
      }
    }
  }
}
