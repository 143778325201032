.mi-home-header {
  background: $medium-blue;
  // background: linear-gradient(to top, #2566b2, #29257a);
  background-image: linear-gradient(to bottom, rgba(2, 15, 29, 0.2) 0%, rgba(2, 15, 29, 0.9) 100%),
    url("../img/mobile-header.jpg");
  background-size: cover;
  background-position: center center;
  color: $white;
  font-size: 1.1rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @include breakpoint(medium down) {
    height: calc(100vh - 78px);
  }
  h1 {
    text-align: center;
    font-size: 2.4rem;
    margin-bottom: 2.5rem;
    @include breakpoint(small down) {
      display: none;
    }
  }
}

.tabs-title > a:focus,
.tabs-title > a[aria-selected="true"] {
  border-bottom: 2px solid;
}
h1,
h2,
h3 {
  font-family: "Din Bold";
}
address {
  font-style: normal;
}
p {
  a {
    color: $medium-blue;
  }
}
table {
  font-family: "Din Medium";
  a {
    color: $medium-blue;
    transition: 0.3s all ease;
  }
}
.accordion.faq {
  max-width: 900px;
  margin: 0 auto;
  *,
  :last-child:not(.is-active) > .accordion-title,
  :last-child > .accordion-content:last-child {
    border-style: none;
  }
  .accordion-title:hover,
  .accordion-title:focus {
    background-color: $white;
  }
  a {
    font-size: 1.1rem;
    color: $medium-blue;
    font-family: "Din Medium";
  }
  .question-number {
    font-size: 1.6rem;
    color: $orange;
    padding-right: 0.8rem;
  }
}
.contact-info {
  i {
    font-size: 2rem;
    text-align: center;
    display: block;
    color: $light-blue;
    margin-bottom: 1.5rem;
  }
  h3 {
    text-align: center;
    font-weight: bold;
    color: $medium-blue;
  }
  h4 {
    font-size: 1.1rem;
    font-weight: bold;
    color: $medium-blue;
    display: inline-block;
  }
  div {
    text-align: center;
  }
}
.generic-content--mi {
  max-width: 900px;
  margin: 0 auto;
  h3 {
    font-size: 1.2rem;
    font-weight: bold;
  }
}
.green-text {
  color: green;
}
.red-text {
  color: $orange;
}
.insurance-plan {
  &__header {
    border-radius: 100px;
    padding: 10px 20px;
    color: $white;
    text-align: center;
    h2 {
      font-size: 1.8rem;
    }

    &--clever {
      background-color: $light-blue;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: -18px;
        width: 20px;
        height: 20px;
        /* background: black; */
        left: calc(50% - 10px);
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border: 10px 10px 0 0 solid black;
        border-top: 15px solid $light-blue;
      }
    }
    &--smart {
      background-color: $medium-blue;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: -18px;
        width: 20px;
        height: 20px;
        /* background: black; */
        left: calc(50% - 10px);
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border: 10px 10px 0 0 solid black;
        border-top: 15px solid $medium-blue;
      }
    }
    &--genius {
      background-color: $purple;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: -18px;
        width: 20px;
        height: 20px;
        /* background: black; */
        left: calc(50% - 10px);
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border: 10px 10px 0 0 solid black;
        border-top: 15px solid $purple;
      }
    }
  }
  &__body {
    a {
      margin: 1rem 0;
      transition: all 0.3s ease;
      display: block;
      &:hover {
        color: $medium-blue;
      }
      button {
        margin: 0;
      }
    }
    .button {
      width: 100%;
      border-radius: 50px;
      font-family: "Din Bold";
    }
    @include breakpoint(small down) {
      button:nth-child(odd) {
        margin-bottom: 50px;
      }
    }
    .hollow {
      border-width: 2px;
    }
    padding: 1rem 0.5rem;
    ul {
      padding: 20px;
      border-radius: 15px;
      margin-bottom: 2rem;
    }
    &--clever {
      h3 {
        text-align: center;
        color: $light-blue;
      }
      ul {
        background-color: rgba($light-blue, 0.05);
      }
      @include breakpoint(small down) {
        border-bottom: 2px solid $light-blue;
        margin-bottom: 40px;
      }
    }
    &--smart {
      h3 {
        text-align: center;
        color: $medium-blue;
      }
      ul {
        background-color: rgba($medium-blue, 0.05);
      }
      @include breakpoint(small down) {
        border-bottom: 2px solid $medium-blue;
        margin-bottom: 40px;
      }
    }
    &--genius {
      h3 {
        text-align: center;
        color: $purple;
      }
      ul {
        background-color: rgba($purple, 0.05);
      }
    }
  }
  &__per-month {
    color: $medium-gray;
    font-size: 0.9rem;
    text-align: center;
  }

  h2,
  h3 {
    font-weight: bold;
    margin-bottom: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    .fas {
      padding-right: 10px;
    }
  }
}
.margin-bottom-3 {
  margin-bottom: 2rem;
}
.button.selected {
  background-color: rgba($purple, 0.8);
  &:hover {
    background-color: rgba($purple, 0.95);
  }
}
.bg-light-blue {
  background-color: $light-blue;
}
.bg-medium-blue {
  background-color: $medium-blue;
}
.bg-purple {
  background-color: $purple;
}
.claims {
  text-align: center;
  color: $white;
  font-weight: bold;
  .cell {
    padding: 2rem 1rem;
    p {
      max-width: 800px;
      margin: 0 auto;
    }
    ul {
      text-align: left;
    }
    a {
      color: rgba($color: $orange, $alpha: 0.7);
      &:hover {
        color: $white;
      }
    }
  }
  h3 {
    font-family: "Din Bold";
  }
}
p.small {
  font-size: 0.95rem;
}
.diagram-step {
  font-size: 1.1rem;
  border: 3px solid rgba($medium-blue, 0.1);
  // background: rgba($medium-blue, 0.05);
  padding: 20px 50px;
  // max-width: 600px;
  margin: 0 auto 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  // border-radius:80px

  p {
    margin-bottom: 5px;
    text-align: center;
    margin-top: 10px;
  }
  &__number {
    font-size: 1.8rem;
    padding: 5px 15px;
    display: inline-block;
    line-height: 1;
    border-bottom: 4px solid $medium-blue;
    // margin: 0 auto 25px;
    color: $orange;
    font-family: "Din Bold";
    margin-bottom: -4px;
  }
  &:not(:last-of-type):after {
    content: "";
    position: absolute;
    width: 4px;
    height: 25px;
    background: rgba($medium-blue, 0.1);
    left: calc(50% - 1px);
    bottom: -28px;
  }
}

.claim-forms-container {
  h5 {
    font-family: "Din Medium";
    margin-top: 60px;
  }
}

h3.strong {
  margin-top: 20px;
}

.contact-procedure {
  &__details {
    background: rgba($medium-blue, 0.1);
    padding: 30px;
    margin-bottom: 20px;
    font-size: 1.2rem;
  }
}
.slogan {
  h3 {
    font-family: "Din Bold";
  }
}
.mi-footer-logo {
  margin: 1.5rem auto;
  max-width: 350px;
  display: block;
}
.mi-plans {
  &__tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 3rem;
    .tabs-title > a {
      // color: white;
      font-size: 1.5rem;
      @include breakpoint(small down) {
        font-size: 1.3rem;
      }
    }
  }
}
.tabs {
  .tabs-title {
    position: relative;
    &__savings {
      position: absolute;
      font-size: 0.8rem;
      bottom: 6px;
      left: 0;
      width: 100%;
      text-align: center;
      right: 0;
      color: $light-blue;
    }
  }
  &--dashboard {
    display: flex;
    flex-direction: column;
    @include breakpoint(medium down) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
    .is-active {
      background-color: $purple;
      color: $white;
      border-radius: 50px;
    }
    .tabs-title {
      padding: 0.1rem;
      border: none;

      a {
        padding: 0.8rem 1rem;
      }
    }
    .tabs-title > a:focus,
    .tabs-title > a[aria-selected="true"] {
      color: $white;
      border: none;
    }
  }
  &-content--dashboard {
    @include breakpoint(large up) {
      padding-left: 2rem;
    }
    @include breakpoint(large down) {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
}

.coverage-row {
  margin: 0 10px;
  img {
    height: 70px;
    margin-bottom: 0.5rem;
  }
  .cell {
    margin-bottom: 2rem;
    font-family: "Din Medium";
    font-size: 1.2rem;
  }
  text-align: center;
  &--plans {
    a {
      color: unset;
    }
    .cell {
      padding: 10px;
      transition: 0.3s all ease;
    }
    img {
      margin-bottom: 2.5rem;
      height: 70px;
      &.tablet {
        height: 90px;
        margin-bottom: 1.5rem;
      }
    }
    .cell:hover {
      background-color: rgba($light-blue, 0.3);
    }
  }
}

.light-bg {
  background: rgba($light-blue, 0.1);
}

.m-t {
  margin-top: 40px;
}
.m-t-h {
  margin-top: 20px;
}
.contact-info--mi {
  h4 {
    margin-bottom: 0.3rem;
  }
  a {
    color: $light-blue;
    margin-bottom: 1rem;
    display: block;
    transition: 0.3s all ease;
    &:hover {
      color: $purple;
    }
  }
}
.mi-footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.jcc-footer {
  // width: 40px;
}
.input-group-label {
  font-family: "Din Medium";
}
th {
  font-size: 0.85rem;
  border-bottom: 2px solid black;
}
td {
  i {
    margin-right: 0.5rem;
  }
}
.device-plan {
  h2 {
    padding-left: 0.5rem;
    font-family: "Din Medium";
    color: white;
  }
  p {
    font-family: "Din Bold";
    color: $orange;
    transition: 0.3s all ease;

    &::after {
      transition: 0.3s all ease;
      content: "\f054";
      font-family: "Font Awesome 5 Pro";
      font-size: 0.9rem;
      padding-left: 10px;
    }
    &:hover::after {
      padding-left: 15px;
    }
  }
  .cell.medium-6 {
    border-radius: 8px;
    background-size: cover;
    &:hover {
      p {
        color: $light-blue;
      }
      p:after {
        padding-left: 15px;
      }
    }
  }
}
.mi-footer-info-section {
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
}

.mobile-insurance-form {
  input[readonly] {
    border: none;
    background-color: rgba($color: $light-gray, $alpha: 0.38);
  }
  input,
  textarea {
    border: 0;
    background-color: rgba($light-blue, 0.25);
  }
  select {
    height: 2.5rem;
  }
}
.review-page-header {
  height: 30vh;
  background-color: $primary-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.payment-review-forms {
  .cell.medium-6:nth-child(1) {
    text-align: right;
    @include breakpoint(medium down) {
      text-align: center;
    }
  }
  .cell.medium-6:nth-child(2) {
    @include breakpoint(medium down) {
      text-align: center;
    }
  }
}
