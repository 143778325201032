article.news-article-wide {
  @extend .grid-x;
  img {
    max-height: 400px;
    position: relative;
    transition: all .5s ease;
    &.default{
      display: flex;
      margin: auto;
    }
  }
  h2 {
    font-family: "Din Bold";
    color: $medium-blue;
    font-size: 1.3rem;
  }
  .cell {
    padding: 1rem;
  }
  .cell:first-of-type {
    position: relative;
    &::before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($light-blue,.0);
      transition: all .5s ease;
    }
    &:hover::before{
      background-color: rgba($light-blue,.1);
    }
  }
  span {
    position: absolute;
    top: 30px;
    right: 0px;
    background-color: #3ba4df;
    color: #fefefe;
    padding: 10px 10px;
  }
  align-items: center;
  a{
      color: $dark-blue;
      font-family: 'Din Medium';
      font-size: .9rem;
      &:hover{
          color: $purple;
      }
  }
}
.single-article-body{
    h2{
        color: $light-blue;
        font-family: 'Din Bold';
        font-size: 1.4rem;
        margin-bottom: 2rem;
    }
    img{
        margin-bottom: 2rem;
    }
}
